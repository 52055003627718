/**
 * Attaches a given access token to a Merchant Configuration API call. Returns merchant invite information
 * @param accessToken 
 */
export async function getFromMCAPI(accessToken, endpoint) {
    const bearer = `Bearer ${accessToken}`;
    const headers = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Pragma: 'no-cache',
        "Authorization": bearer
    });

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(endpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}