import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { PublicClientApplication, LogLevel } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const hostname = window.location.hostname;

// Configuration object constructed.
const config = {
    auth: {
        clientId: hostname === "management.towpay.com" ? 'df4f58bd-8dc2-4a8c-b9e1-030171a4cf73' : '235b9655-d404-427c-a65b-1c34da1bb6d2',
        authority: "https://login.microsoftonline.com/7d245399-5b73-4271-9255-4c65e57f5aa5",
        redirectUri: window.location.origin
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                    default:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

// create PublicClientApplication instance
const publicClientApplication = new PublicClientApplication(config);

ReactDOM.render(
    <React.Fragment>
        <MsalProvider instance={publicClientApplication}>
            <BrowserRouter basename={baseUrl}>
                <App />
            </BrowserRouter>
        </ MsalProvider>
    </React.Fragment>
    ,rootElement);

registerServiceWorker();

