import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { useMsal, AuthenticatedTemplate } from "@azure/msal-react";

import { configs } from '../configurations';
import useForm from "../useForm";
import validateMerchantInvite from './merchantInviteValidationRules';
import { getFromMCAPI } from './merchantConfigurationApi';

export function InviteMerchant() {
    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = useState(null);
    const [envConfigs, setEnvConfigs] = useState({});
    const [partners, setPartners] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Invite Merchant";
    }, []);

    const acquireAccessToken = (configurations) => {
        const accessTokenRequest = {
            account: accounts[0]
        }

        if (inProgress === InteractionStatus.None) {
            // Silently acquires an access token which is then attached to a request for Microsoft Graph data
            instance.acquireTokenSilent(accessTokenRequest)
                .then((response) => {
                    setAccessToken(response.accessToken);
                    getPartnerList(configurations, response.accessToken);
                })
                .catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);
                }
                console.log(error);
            });
        }
    };

    const getPartnerList = (configs, token) => {
        var endpoint = configs.merchantConfigurationBaseUri + configs.partnersEndpoint;
        getFromMCAPI(token, endpoint)
            .then((response) => {
                setPartners(response);
            });
    };

    // Functional component equivalent of componentDidMount()
    useEffect(() => {
        let mounted = true;
        configs()
            .then(items => {
                if (mounted) {
                    setEnvConfigs(items)
                    acquireAccessToken(items)
                }
            });
        return () => mounted = false;
    }, []);

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
    } = useForm(createAndSendMerchantInvite, validateMerchantInvite);

    async function createAndSendMerchantInvite() {
        if (values) {
            const headers = {"Accept": "application/json", "Content-Type": "application/json","Pragma": "no-cache"};
            const headersWithAuth = JSON.parse(JSON.stringify(headers));
            headersWithAuth["Authorization"] = "Bearer " + accessToken;

            let fetchOptions = {
                method: "POST",
                headers: headersWithAuth
            };

            //  First, we try to create a MerchantInvite
            const createInviteEndpoint = envConfigs.merchantConfigurationBaseUri + envConfigs.merchantInvitesEndpoint;

            fetchOptions["body"] = JSON.stringify({
                sentBy: accounts[0].username,
                emailAddress: values.inviteeEmail,
                country: values.country,
                partnerId: values.partner
            });

            let merchantInvite = await fetch(createInviteEndpoint, fetchOptions).then(response => response.json());

            console.log(merchantInvite);
            navigate('/'); //   ManageInvites
        }
    }

    return (
        <div>
            <AuthenticatedTemplate>
                <h1>Invite Merchant</h1>

                <p>Enter the email address and select the Partner; click "Invite"</p>
                <form onSubmit={handleSubmit} noValidate>
                    <div
                        className="mb-3"
                    >
                        <label
                            htmlFor="inviteeEmail"
                            className="form-label"
                        >
                            Email <span className="form-text">(The merchant Controller or Owner completing the application.)</span>
                        </label>
                        <input
                            autoComplete="off"
                            className={`form-control ${errors.inviteeEmail && 'is-invalid'}`}
                            name="inviteeEmail"
                            onChange={handleChange}
                            value={values.inviteeEmail || ''}
                        />
                        {errors.inviteeEmail && (
                            <div className="text-danger">{errors.inviteeEmail}</div>
                        )}
                    </div>
                    <div
                        className="mb-3"
                    >
                        <label
                            htmlFor="partner"
                            className="form-label"
                        >
                            Partner <span className="form-text">(The TowPay Partner for this merchant.)</span>
                        </label>
                        <select
                            autoComplete="off"
                            className={`form-select ${errors.partner && 'is-invalid'}`}
                            name="partner"
                            onChange={handleChange}
                            defaultValue={values.partner || ''}
                        >
                            <option disabled value="">Choose...</option>
                            {
                                partners.map(partner => (
                                    <option key={partner.partnerId} value={partner.partnerId}>{partner.name}</option>
                                ))
                            }
                        </select>
                        {errors.partner && (
                            <div className="text-danger">{errors.partner}</div>
                        )}
                    </div>
                    <div
                        className="mb-3"
                    >
                        <label htmlFor="country"
                            className="form-label"
                        >
                            Country:
                        </label>
                        <select
                            autoComplete="off"
                            className={`form-select ${errors.country && 'is-invalid'}`}
                            name="country"
                            onChange={handleChange}
                            defaultValue={values.country || ''}
                        >
                            <option disabled value="">Choose...</option>
                            <option key="us" value="us">United States</option>
                            <option key="ca" value="ca">Canada</option>
                        </select>
                        {errors.country && (
                            <div className="text-danger">{errors.country}</div>
                        )}
                    </div>
                    <button type="submit" className="btn btn-primary">Invite</button>
                </form>
            </AuthenticatedTemplate>
        </div>
    );
}
