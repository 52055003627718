export default function validate(formData) {
    let errors = {};

    if (!formData.partner) {
        errors.partner = "Partner error message";
    }

    if (!formData.country) {
        errors.country = "Country error message";
    }

    if (!formData.inviteeEmail) {
        errors.inviteeEmail = "Please enter your email Address.";
    }
    if (typeof formData.inviteeEmail !== "undefined") {
        var pattern = new RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
        if (!pattern.test(formData.inviteeEmail)) {
            errors.inviteeEmail = "Please enter a valid email address.";
        }
    }

    return errors;
}