import { Component, useMemo, useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { getFromMCAPI } from "./merchantConfigurationApi";
import { configs } from "../configurations";

function InvitesTable({ data }) {
    const invites = useMemo(() => data, [data]);
    const columns = useMemo(() =>
        [
            {
                header: 'Invite Id',
                accessor: 'merchantInviteId'
            },
            {
                header: 'TowPayMID',
                accessor: 'merchantId'
            },
            {
                header: 'Partner',
                accessor: 'partnerName'
            },
            {
                header: 'Country',
                accessor: 'country'
            },
            {
                header: 'Controller Email',
                accessor: 'emailAddress'
            },
            {
                header: 'Sent By',
                accessor: 'sentBy'
            },
            {
                header: 'Sent On',
                accessor: 'inviteSent'
            },
            {
                header: 'Status',
                accessor: 'status'
            }
        ],
        []
    );

    const tableInstance = useTable(
        {
            columns,
            data: invites,
            initialState: {
                sortBy: [
                    {
                        id: 'inviteSent',
                        desc: true
                    }
                ]
            }
        },
        useSortBy);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance;

    return (
        <table
            {...getTableProps()}
            className='table table-striped' aria-labelledby="tabelLabel"
        >
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr
                        {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                style={{
                                }}
                            >
                                {column.render('header')}
                                {column.isSorted
                                    ? column.isSortedDesc
                                        ? <i className="bi bi-arrow-down"></i>
                                        : <i className="bi bi-arrow-up"></i>
                                    : ''}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody
                {...getTableBodyProps()}
            >
                {rows.length === 0 ? <tr><td>No Invites, currently.</td><td></td><td></td><td></td></tr> :
                    rows.map(row => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                    <td
                                        {...cell.getCellProps()}
                                        style={{
                                        }}
                                    >
                                        {/*{console.log(cell.value)}*/}
                                        {cell.column.id == 'inviteSent'
                                            ? (new Date(cell.value)).toLocaleString()
                                            : cell.render('Cell')
                                        }
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

function GetMerchantInviteData({ configData }) {
    const { instance, inProgress, accounts } = useMsal();
    const [apiData, setApiData] = useState();
    const accessTokenRequest = {
        scopes: [configData.scopeInvitesReadAll],
        account: accounts[0]
    }

    if (!apiData && inProgress === InteractionStatus.None
        && configData.merchantConfigurationBaseUri !== undefined
        && configData.merchantInvitesEndpoint !== undefined
        && configData.scopeInvitesReadAll !== undefined
    ) {
        instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;

            // Call your API with token
            getFromMCAPI(accessToken, configData.merchantConfigurationBaseUri + configData.merchantInvitesEndpoint)
                .then((response) => { setApiData(response) });
        }).catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(accessTokenRequest);
            }
            console.log(error);
        })
    }
    if (apiData == null) {
        console.log("Loading apiData...");
        return <p>loading...</p>;
    }

    return <InvitesTable data={apiData} />;
}

export class ManageInvites extends Component {
    static displayName = ManageInvites.name;

    constructor(props) {
        super(props);

        this.state = {
            invites: [],
            loading: true,
            envConfigs: {}
        };
    }

    async componentDidMount() {
        document.title = "Manage Invites";
        const envConfigs = await configs();
        this.setState({ envConfigs });
    }

    render() {
        return (
            <div>
                <UnauthenticatedTemplate>
                    <p>Welcome to TowPay's Merchant Management site. You are not logged in.  To continue, click on the Log In button and enter your information.</p>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    <h1 id="tabelLabel" >Manage Merchant Invites</h1>
                    <p>Once you've invited a Merchant, they will appear below.  You can view the current state of their onboarding process with TowPay.</p>
                    <p>You can Invite a Merchant to onboard via TowPay payments by clicking on the link Invite Merchant.</p>
                    <p>To view their Connect Account, log into the <a href="https://dashboard.stripe.com/dashboard" target="_blank">TowPay Platform Account Dashboard</a>.</p>
                    <GetMerchantInviteData configData={this.state.envConfigs} />
                </AuthenticatedTemplate>
            </div>
        );
    }
}
