export async function configs() {
    const headers = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Pragma: 'no-cache'
    });
    const options = {
        method: "GET",
        headers: headers
    };

    const res = await fetch('api/configurations', options);
    const json = await res.json();
    return json;
}
export default configs;