import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { configs } from '../configurations.js';

import './NavMenu.css';

function signInClickHandler(instance) {
    instance.loginRedirect();
}

// SignInButton Component returns a button that invokes a popup login when clicked
function SignInButton() {
    // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
    const { instance } = useMsal();

    return <button onClick={() => signInClickHandler(instance)}>Log In</button>
};

function signOutClickHandler(instance, homeAccountId) {
    const logoutRequest = {
        account: instance.getAccountByHomeId(homeAccountId),
        postLogoutRedirectUri: window.location.origin
    }
    instance.logoutRedirect(logoutRequest);
}

// SignOutButton Component returns a button that invokes a redirect logout when clicked
function SignOutButton() {
    // useMsal hook will return the PublicClientApplication instance you provided to MsalProvider
    const { instance, accounts } = useMsal();
    const homeAccountId = accounts[0].homeAccountId;

    return <button onClick={() => signOutClickHandler(instance, homeAccountId)}>Log Out</button>
};

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
        collapsed: true,
        envConfigs: {}
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

    async componentDidMount() {
        const envConfigs = await configs();
        this.setState({ envConfigs });
    }
  render () {
    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                <Container>
                    <NavbarBrand tag={Link} to="/"><img src="towpay-logo.png" className="logo" alt="TowPay Logo" />Merchant Management Website</NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <AuthenticatedTemplate>
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/">Manage Invites</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/invite-merchant">Invite Merchant</NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                        <SignOutButton />
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/contact-support">Contact Support</NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                        <SignInButton />
                    </UnauthenticatedTemplate>
                </Container>
            </Navbar>
        </header>
    );
  }
}
