import React, { Component } from 'react';

export class ContactSupport extends Component {
    static displayName = ContactSupport.name;

    render() {
        return (
            <div>
                <h1>Contact TowPay Support</h1>
                <p>If you have a question or complaint, please contact us at <a href="mailto:support@towpay.com">support@towpay.com</a>.</p>
                <p>Or, you may also contact us by writing to:<br /><br />

                    &nbsp;&nbsp;&nbsp;ATTN: TowPay Support<br />
                    &nbsp;&nbsp;&nbsp;407 Broad St<br />
                    &nbsp;&nbsp;&nbsp;Chattanooga, TN 37406</p>
            </div>
        );
    }
}
