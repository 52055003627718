import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { InviteMerchant } from './components/InviteMerchant';
import { ManageInvites } from './components/ManageInvites';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { ContactSupport } from './components/ContactSupport';

import './bootstrap-icons.css'
import './custom.css'
import { Routes } from 'react-router-dom';

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
        <Layout>
            <Routes>
                <Route path='/contact-support' element={<ContactSupport />} />
            </Routes>
            <AuthenticatedTemplate>
                <Routes>
                    <Route exact path='/' element={<ManageInvites />} />
                    <Route exact path='/invite-merchant' element={<InviteMerchant />} />
                </Routes>
            </AuthenticatedTemplate>
        </Layout>
    );
  }
}
